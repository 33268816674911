import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  FormControl,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  TextField,
  Typography,
} from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import './App.css'
import moment from 'moment/moment'
function ContractDetail(props) {
  const {
    handleAccordionClick,
    accordionRef,
    openAccordion,
    contractList,
    handleSelectedContractNumber,
    SelectedContractNumber,
    contractStatus,
    firstName,
    lastName,
    address,
    city,
    state,
    postalCode,
    Industry,
    coverageDescription,
    effectiveDt,
    expirationDt,
    dealerName,
    TotalCoverage,
    deductible,
    lolUsedMoneyFormat,
    lolAvailableMoneyFormat,
    totalLiabilityMoneyFormat,
    email,
    primaryPhone,
    open,
    handleClick,
    handleClose,
    anchorEl,
    handleAddNewContract,
  } = props
  return (
    <div className='d-flex'>
      <Accordion
        ref={accordionRef}
        expanded={openAccordion}
        onClick={handleAccordionClick}
        className='accordion-custom'
      >
        <AccordionSummary
          className='accordion-summary-custom'
          expandIcon={<ExpandMoreIcon sx={{ fontWeight: 700, color: '#1e384b' }} />}
          aria-controls='panel1-content'
          id='panel1-header'
        >
          <List className='list-custom p-0'>
            <ListItem
              className='custom-padding-margin-width-list px-0'
              key='selectedContractNumber'
            >
              <ListItemText
                primaryTypographyProps={{
                  width: '7.6rem',
                  fontSize: '14px',
                }}
                primary={'Contract Number: '}
                sx={{ flex: 'none' }}
              />

              <FormControl>
                <Autocomplete
                  size='small'
                  disablePortal
                  options={contractList?.map((item) => ({
                    label: item?.attributes?.externalId,
                    value: item?.attributes?.externalId,
                  }))}
                  className='custom-autocomplete'
                  getOptionLabel={(option) => option?.label || ''}
                  noOptionsText='No Contract found'
                  value={contractList.find(
                    (option) => option?.attributes?.externalId === SelectedContractNumber,
                  )}
                  onChange={(event, newValue) => {
                    if (newValue) {
                      handleSelectedContractNumber(event, newValue.value)
                    } else {
                      handleSelectedContractNumber(event, '')
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      placeholder={SelectedContractNumber}
                      InputProps={{
                        ...params.InputProps,
                        sx: {
                          'input::placeholder': {
                            color: 'black',
                            opacity: 1,
                          },
                        },
                      }}
                    />
                  )}
                  isOptionEqualToValue={(option, value) => option?.value === value}
                />
              </FormControl>
            </ListItem>

            {contractStatus?.length > 0 && (
              <ListItem
                className='p-0 m-0'
                sx={{
                  width: '35%',
                }}
                key='contractStatus'
              >
                <ListItemText
                  primaryTypographyProps={{
                    width: '3.3rem',
                    fontSize: '14px',
                  }}
                  primary={'Status : '}
                  sx={{ flex: 'none' }}
                />
                <ListItemText
                  secondaryTypographyProps={{
                    fontSize: '13px',
                    fontWeight: 600,
                    marginTop: '2px',
                    color: contractStatus[0] !== 'A' ? '#dc3545' : '#1e384b',
                  }}
                  secondary={contractStatus}
                  sx={{ flex: 'none' }}
                />
              </ListItem>
            )}

            {firstName?.length > 0 && (
              <ListItem
                className='p-0 m-0'
                sx={{
                  width: '70%',
                }}
                key='firstName'
              >
                <ListItemText
                  primaryTypographyProps={{
                    width: '4.6rem',
                    fontSize: '14px',
                  }}
                  primary={'Full Name: '}
                  sx={{ flex: 'none' }}
                ></ListItemText>
                <ListItemText
                  className='mt-1'
                  secondaryTypographyProps={{
                    fontSize: '13px',
                    width: '12vw',
                  }}
                  secondary={firstName + ' ' + lastName}
                  sx={{ flex: 'none' }}
                ></ListItemText>
              </ListItem>
            )}
            {address?.length > 0 && (
              <ListItem className='p-0 m-0' key='address'>
                <ListItemText
                  primaryTypographyProps={{
                    width: '4rem',
                    fontSize: '14px',
                  }}
                  primary={'Address : '}
                  sx={{ flex: 'none' }}
                ></ListItemText>
                <ListItemText
                  className='mt-1'
                  secondaryTypographyProps={{
                    fontSize: '13px',
                    width: '22vw',
                  }}
                  secondary={
                    `${address ? address + ', ' : ''}` +
                    `${city ? city + ', ' : ''}` +
                    `${state ? state + ', ' : ''}` +
                    postalCode
                  }
                ></ListItemText>
              </ListItem>
            )}
          </List>
        </AccordionSummary>
        <div
          className='border border-light rounded pt-3 pe-3 pb-2 ps-2 w-100'
          style={{ borderColor: '#e8eaf0', borderWidth: '2px' }}
        >
          <AccordionDetails sx={{ '& .MuiAccordionSummary-content': { margin: 0 } }}>
            <div className='d-flex w-100'>
              <div style={{ width: '70%' }}>
                <Typography sx={{ fontSize: '1.3rem' }}>Contract Info</Typography>
                <div
                  className='d-flex w-100'
                  style={{
                    backgroundColor: '#FBFBFB',
                    overflow: 'auto',
                    minHeight: '28vh',
                  }}
                >
                  <div
                    xs={7}
                    className='pt-1 pb-1 ps-3'
                    style={{
                      overflow: 'none',
                      width: '60%',
                    }}
                  >
                    <List sx={{ padding: 0 }}>
                      <ListItem className='p-0 pe-3 m-0' key='status'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '3.6rem',
                            fontSize: '14px',
                          }}
                          primary={'Status : '}
                          sx={{ flex: 'none' }}
                        />
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                            fontWeight: 600,
                            color: contractStatus?.[0] !== 'A' ? '#dc3545' : '#1e384b',
                          }}
                          secondary={contractStatus}
                          sx={{ flex: 'none' }}
                        />
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='industry'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '4.2rem',
                            fontSize: '14px',
                          }}
                          primary={'Industry : '}
                          sx={{ flex: 'none' }}
                        />
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                          }}
                          secondary={Industry}
                        />
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='coverageDescription'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '3.1rem',
                            fontSize: '14px',
                          }}
                          primary={'Type : '}
                          sx={{ flex: 'none' }}
                        />
                        <ListItemText
                          secondaryTypographyProps={{
                            minWidth: '4rem',
                            fontSize: '13px',
                          }}
                          secondary={coverageDescription}
                        />
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='effectiveDt'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '6.5rem',
                            fontSize: '14px',
                          }}
                          primary={'Effective Date : '}
                          sx={{ flex: 'none' }}
                        />
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                          }}
                          secondary={moment.utc(effectiveDt)?.isValid() ? effectiveDt : ''}
                        />
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='expirationDt'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '7rem',
                            fontSize: '14px',
                          }}
                          primary={'Expiration Date : '}
                          sx={{ flex: 'none' }}
                        />
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                          }}
                          secondary={expirationDt}
                          sx={{ flex: 'none' }}
                        />
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='dealerName'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '3.7rem',
                            fontSize: '14px',
                          }}
                          primary={'Dealer : '}
                          sx={{ flex: 'none' }}
                        />
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                          }}
                          secondary={dealerName}
                        />
                      </ListItem>
                    </List>
                  </div>
                  <div
                    xs={5}
                    style={{
                      padding: '4px 0px',
                      overflow: 'none',
                      width: '40%',
                    }}
                  >
                    <List sx={{ padding: 0 }}>
                      <ListItem className='p-0 pe-3 m-0' key='coverageLength'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '5rem',
                            fontSize: '14px',
                          }}
                          primary={'Coverage : '}
                          sx={{ flex: 'none', wordWrap: 'break-word' }}
                        />
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                          }}
                          secondary={TotalCoverage ? TotalCoverage / 12 + ' Year' : null}
                          sx={{ flex: 'none' }}
                        />
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='deductible'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '5rem',
                            fontSize: '14px',
                          }}
                          primary={'Deductible : '}
                          sx={{ flex: 'none' }}
                        />
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                          }}
                          secondary={deductible}
                        />
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='lolUsed'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '5rem',
                            fontSize: '14px',
                          }}
                          primary={'LOL Used : '}
                          sx={{ flex: 'none' }}
                        />
                        <ListItemText
                          secondary={lolUsedMoneyFormat}
                          sx={{ flex: 'none' }}
                          secondaryTypographyProps={{
                            noWrap: true,
                            maxWidth: '100%',
                            fontSize: '13px',
                          }}
                        />
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='lolAvailable'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '7rem',
                            fontSize: '14px',
                          }}
                          primary={'LOL Availability : '}
                          sx={{ flex: 'none' }}
                        />
                        <ListItemText
                          secondary={lolAvailableMoneyFormat}
                          sx={{ flex: 'none' }}
                          secondaryTypographyProps={{
                            noWrap: true,
                            maxWidth: '100%',
                            fontSize: '13px',
                          }}
                        />
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='totalLiability'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '6.2rem',
                            fontSize: '14px',
                          }}
                          primary={'Total Liability : '}
                          sx={{ flex: 'none' }}
                        />
                        <ListItemText
                          secondary={totalLiabilityMoneyFormat}
                          sx={{ flex: 'none' }}
                          secondaryTypographyProps={{
                            noWrap: true,
                            maxWidth: '100%',
                            fontSize: '13px',
                          }}
                        />
                      </ListItem>
                    </List>
                  </div>
                </div>
              </div>
              <div style={{ width: '60%', marginLeft: '-1.8rem' }}>
                <Typography
                  sx={{
                    fontSize: '1.3rem',
                    paddingLeft: '0px 0px 5px 0px',
                    marginLeft: '-0.5rem',
                  }}
                >
                  Customer Details
                </Typography>
                <div
                  className='mt-0'
                  style={{
                    backgroundColor: '#FBFBFB',
                    overflow: 'auto',
                    minHeight: '28vh',
                  }}
                >
                  <div xs={12} style={{ padding: '4px 0px', overflow: 'none' }}>
                    <List sx={{ padding: '0px' }}>
                      <ListItem className='p-0 pe-3 m-0' key='fullName'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '4.8rem',
                            fontSize: '14px',
                          }}
                          primary={'Full Name: '}
                          sx={{ flex: 'none' }}
                        ></ListItemText>
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                          }}
                          secondary={firstName + ' ' + lastName}
                          sx={{ flex: 'none' }}
                        ></ListItemText>
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='fullAddress'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '4.2rem',
                            fontSize: '14px',
                          }}
                          primary={'Address : '}
                          sx={{ flex: 'none' }}
                        ></ListItemText>
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                          }}
                          secondary={
                            `${address ? address + ', ' : ''}` +
                            `${city ? city + ', ' : ''}` +
                            `${state ? state + ', ' : ''}` +
                            postalCode
                          }
                        ></ListItemText>
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='email'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '3rem',
                            fontSize: '14px',
                          }}
                          primary={'Email : '}
                          sx={{ flex: 'none' }}
                        ></ListItemText>
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                          }}
                          secondary={email}
                          sx={{ flex: 'none' }}
                        ></ListItemText>
                      </ListItem>
                      <ListItem className='p-0 pe-3 m-0' key='primaryPhone'>
                        <ListItemText
                          primaryTypographyProps={{
                            width: '6.5rem',
                            fontSize: '14px',
                          }}
                          primary={'Primary Phone : '}
                          sx={{ flex: 'none' }}
                        ></ListItemText>
                        <ListItemText
                          secondaryTypographyProps={{
                            fontSize: '13px',
                          }}
                          secondary={primaryPhone}
                          sx={{ flex: 'none' }}
                        ></ListItemText>
                      </ListItem>
                    </List>
                  </div>
                </div>
              </div>
            </div>
          </AccordionDetails>
        </div>
      </Accordion>
      <div className='ps-1' style={{ width: '4.5%' }}>
        <Button
          id='basic-button'
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup='true'
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}
          style={{
            backgroundColor: '#e8eaf0',
            color: 'black',
            fontWeight: 600,
          }}
        >
          . . .
        </Button>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => window.location.reload()}>Start a New Service</MenuItem>
          <MenuItem onClick={handleAddNewContract}>Add a New Contract</MenuItem>
        </Menu>
      </div>
    </div>
  )
}
export default ContractDetail
