import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import moment from 'moment'
import CancelServiceOrderDialog from './CancelServiceOrder'
import ConfirmationDialog from './ConfirmationDialog'
import ConfirmationRescheduleDialog from './ConfirmationRescheduleDialog'
import FinalConfirmationDialog from './FinalConfirmationDialog'
import ReassignDialog from './ReassignDialog'
import RescheduleAppointmentDialog from './RescheduleAppointmentDialog'
import RescheduleDialog from './RescheduleDialog'
import {
  bookAppointment,
  getAppointments,
  getCancelReasonsList,
  getOrderDetails,
  getRescheduleReasonList,
  getServicerDetail,
  getWeeklyAvailabilityByServicer,
} from '../../api/serviceavenger'

export default function ExistingAppointmentDialog(props) {
  const {
    showExistingAppointmentDialog,
    rescheduleDialogParent,
    closeRescheduleDialogParent,
    existingAppointment,
    reassignDialogParent,
    closeReassignDialogParent,
    cancelDialogParent,
    closeCancelDialogParent,
    getServicerOrderParent,
    contractNumber,
    servicerSubStatus,
  } = props
  const today = moment()
  const [servicerData, setServicerData] = useState()
  const [uiservicerData, setUiServicerData] = useState()
  const [reqBodyGetAvailability, setReqBodyGetAvailability] = useState()
  const [serviceOrder, setServiceOrder] = useState({})
  const [reassignOpen, setReassignOpen] = useState(false)
  const [reassignData, setReassignData] = useState()
  const [bookingContent, setBookingContent] = useState([])
  const [handleConfirmDialog, setHandleConfirmDialog] = useState(false)
  const [finalConfirmDialog, setFinalConfirmDialog] = useState(false)
  const [appointmentDate, setAppointmentDate] = useState('')
  const [appointmentDay, setAppointmentDay] = useState('')
  const [reassignReason, setReassignReason] = useState()
  const [reassignIndex, setReassignIndex] = useState()
  const [totalReassignData, setTotalReassignData] = useState([])
  // activefirmAvailabilitydialog not used, why do we need?
  // eslint-disable-next-line no-unused-vars
  const [activefirmAvailabilitydialog, setActivefirmAvailabilitydialog] = useState(1)
  // firmAvilabilityDialog not used, why do we need?
  // eslint-disable-next-line no-unused-vars
  const [firmAvilabilityDialog, setFirmAvilabilityDialog] = useState(false)
  const [dropDownAvailability, setDropDownAvailability] = useState(false)
  const [consumerDetails, setConsumerDetails] = useState({})
  const [productDetails, setProductDetails] = useState({})
  const [reassignNote, setReassignNote] = useState('')
  const [failureId, setFailureId] = useState('')
  const [reassignDataObj, setReassignDataObj] = useState(null)

  const handleClose = () => {
    setReassignOpen(false)
    closeReassignDialogParent()
  }

  const getServiceOrderAfterBooking = async () => {}

  //cancel
  const [cancelReasons, setCancelReasons] = useState([])
  const [openCancelService, setOpenCancelService] = useState(false)

  const getCancelReasons = async () => {
    try {
      const response = await getCancelReasonsList()
      if (response?.data?.isSuccess) {
        setCancelReasons(response?.data?.items)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    if (showExistingAppointmentDialog && servicerData) {
      getCancelReasons()
    }
  }, [showExistingAppointmentDialog, servicerData])

  const handleToggleCancelService = () => {
    setOpenCancelService((prev) => !prev)
    closeCancelDialogParent()
  }

  //reschedule
  const [rescheduleDialog, setRescheduleDialog] = useState(false)
  const [rescheduleAppointmentDialog, setRescheduleAppoitnmentDialog] = useState(false)
  const [rescheduleReasonList, setRescheduleReasonList] = useState([])
  const [rescheduleDialogValue, setRescheduleDialogValue] = useState(null)
  const [rescheduleAppointmentDialogData, setRescheduleAppointmentDialogData] = useState(null)
  const [rescheduleAppointmentAEObject, setRescheduleAppointmentAEObject] = useState(null)
  const [serviceOrderListObject, setServiceOrderListObject] = useState(null)
  const [finalBookingTimeSlot, setFinalBookingTimeSlot] = useState('')
  const [rescheduleResponse, setRescheduleResponse] = useState()
  const [openTimeSlotDialog, setOpenTimeSlotDialog] = useState(false)
  const [finalConfirmationReschedule, setFinalConfirmationReschedule] = useState(false)

  const getRescheduleReason = async () => {
    try {
      const response = await getRescheduleReasonList(servicerData)
      if (response && response?.data?.isSuccess) {
        setRescheduleReasonList(response?.data?.items)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  const handleRescheduleDialog = () => {
    setRescheduleDialog(false)
    closeRescheduleDialogParent()
    setRescheduleDialogValue(undefined)
  }

  const handleOpenRescheduleAppointMentDialog = async () => {
    if (serviceOrderListObject?.serviceOrder?.providerType === 'A&E') {
      try {
        const response = await getAppointments(
          rescheduleAppointmentAEObject?.customer?.address1,
          rescheduleAppointmentAEObject?.customer?.address2,
          'firm',
          rescheduleAppointmentAEObject?.customer?.businessName,
          rescheduleAppointmentAEObject?.customer?.cellPhone,
          rescheduleAppointmentAEObject?.customer?.city,
          rescheduleAppointmentAEObject?.customer?.countryCode,
          rescheduleAppointmentAEObject?.customer?.customerId,
          rescheduleAppointmentAEObject?.service?.dealerId,
          rescheduleAppointmentAEObject?.customer?.email,
          rescheduleAppointmentAEObject?.customer?.firstName,
          rescheduleAppointmentAEObject?.customer?.lastName,

          rescheduleAppointmentAEObject?.customer?.otherPhone,
          rescheduleAppointmentAEObject?.customer?.stateProvince,
          rescheduleAppointmentAEObject?.customer?.zipCode,
          rescheduleAppointmentAEObject?.product?.brandCode,
          rescheduleAppointmentAEObject?.product?.modelNumber,
          rescheduleAppointmentAEObject?.product?.productCode,
          rescheduleAppointmentAEObject?.product?.tierCode,
          rescheduleAppointmentAEObject?.product?.serialNumber,
          rescheduleAppointmentAEObject?.service?.beginSearchDate,
          rescheduleAppointmentAEObject?.service?.procedureCode,
          rescheduleAppointmentAEObject?.service?.serviceElevation,
          rescheduleAppointmentAEObject?.service?.serviceNotice,
          rescheduleAppointmentAEObject?.service?.serviceType,
          rescheduleAppointmentAEObject?.warranty?.contractNumber,
          rescheduleAppointmentAEObject?.warranty?.coverageBeginDate,
          rescheduleAppointmentAEObject?.warranty?.coverageEndDate,
          rescheduleAppointmentAEObject?.warranty?.warrantyType,
          rescheduleAppointmentAEObject?.warranty?.serviceTypeId,
          rescheduleAppointmentAEObject?.service?.warrantyTypeId,

          rescheduleAppointmentAEObject?.service?.serviceLocationId,

          rescheduleAppointmentAEObject?.product?.productSequenceNumber,
          'reschedule',
          '',
          rescheduleAppointmentAEObject?.product?.installDate,
          rescheduleAppointmentAEObject?.service?.totalLiability,
          rescheduleAppointmentAEObject?.service?.serviceOrderNumber,
          '',
          'A&E',
        )
        if (response && response?.data?.isSuccess) {
          const data = response.data.data
          let list = []
          const dateArray = []
          for (let i = 0; i < 14; i++) {
            const date = today.clone().add(i, 'days')
            const todayDate = date.format('YYYY-MM-DD')
            const day = date.format('dddd')
            list = data.filter((item) => item.date === todayDate)
            dateArray.push({
              dateObject: date,
              date: date.format('YYYY-MM-DD'),
              timeBand: list[0]?.availability,
              day: day,
            })
          }
          setRescheduleAppointmentDialogData(dateArray)
          setRescheduleDialog(false)
          setRescheduleAppoitnmentDialog(true)
        }
      } catch (error) {
        toast.error(error.message || 'Something Went Wrong')
      }
    } else {
      const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
      const offsetInMinutes = new Date().getTimezoneOffset()
      const reqBody = {
        servicerAccountId: servicerData?.servicerAccountId,
        customer: {
          zipCode: rescheduleAppointmentAEObject?.customer?.zipCode,
        },
        product: {
          brandCode: rescheduleAppointmentAEObject?.product?.brandCode,
          tierCode: rescheduleAppointmentAEObject.product?.tierCode,
          productCode: rescheduleAppointmentAEObject.product?.tierCode,
        },
        service: {
          beginSearchDate: moment(new Date()).format('YYYY-MM-DD HH:mm:ss'),
          numberOfDays: 7,
        },
        warranty: {
          contractNumber: contractNumber,
        },
        type: 'forced',
        timeZone: timeZone,
        timeZoneMinuteDifference: offsetInMinutes,
      }
      setReqBodyGetAvailability(reqBody)
      try {
        const response = await getWeeklyAvailabilityByServicer(reqBody)
        if (response && response?.data?.isSuccess) {
          if (response.data.data && Object.keys(response.data.data).length) {
            const data = response.data.data
            const dateArray = []
            const orderMapping = { morning: 0, evening: 2, afternoon: 1 }
            function customSort(a, b) {
              return orderMapping[a] - orderMapping[b]
            }
            for (let i = 0; i < 14; i++) {
              const date = today.clone().add(i, 'days')
              const day = date.format('dddd')
              let timeBand = []
              if (data[day]) {
                timeBand = data[day][0].timeBand.sort(customSort)
              }

              dateArray.push({
                dateObject: date,
                date: date.format('YYYY-MM-DD'),
                day: day,
                timeBand: timeBand,
                sameDay:
                  timeBand.length > 0
                    ? data[day][0].sameDayCutOff + '' + data[day][0].sameDayAmPm
                    : '',
                nextDay:
                  timeBand.length > 0
                    ? data[day][0].nextDayCutOff + '' + data[day][0].nextDayAmPm
                    : '',
                servicerId: timeBand.length > 0 ? data[day][0].servicerAccount : '',
                servicerName: timeBand.length > 0 ? data[day][0].serviceProvider : '',
              })
            }
            setRescheduleAppointmentDialogData(dateArray)
            setRescheduleDialog(false)
            setRescheduleAppoitnmentDialog(true)
          } else {
            toast.error(response.data.message)
            setRescheduleDialog(false)
          }
        }
      } catch (error) {
        toast.error(error?.message)
      }
    }
  }

  const handleCloseRescheduleAppointmentDialog = () => {
    setRescheduleAppoitnmentDialog(false)
    getRescheduleReason()
    setRescheduleDialogValue(undefined)
    closeRescheduleDialogParent()
  }

  const handleRescheduleAppointment = async (
    selectedData,
    selectedSlot,
    bookingNote,
    setBookingNote,
  ) => {
    const customer = rescheduleAppointmentAEObject?.customer
    const rescheduleCode = rescheduleReasonList.filter(
      (item) => item.reasonName === rescheduleDialogValue,
    )
    const reqBody = {
      customer: customer,
      product: {
        sequenceNumber: serviceOrderListObject?.product?.productSequenceNumber,
        productPurchaseDate: moment(serviceOrderListObject?.product?.purchaseDate).format(
          'YYYY-MM-DD',
        ),
        productSubCategory: serviceOrderListObject?.product?.productName,
      },
      warranty: {
        warrantyType: serviceOrderListObject?.serviceOrder?.warrantyType,
        coverageBeginDate: moment(serviceOrderListObject?.contract?.effectiveDate).format(
          'YYYY-MM-DD',
        ),
        coverageEndDate: moment(serviceOrderListObject?.contract?.expirationDate).format(
          'YYYY-MM-DD',
        ),
        serviceAvengerContractNumber: serviceOrderListObject?.serviceOrder?.serviceContractNumber,
        externalContractNumber: serviceOrderListObject?.serviceOrder?.externalContractNumber || '',
      },
      service: {
        serviceOrderNumber: serviceOrderListObject?.serviceOrder?.serviceOrderId,
        serviceCallDate: today?.format('YYYY-MM-DD hh:mm:ss'),
        failureDate: moment(serviceOrderListObject?.serviceOrder?.failureDate).format('YYYY-MM-DD'),
        serviceRequestType: serviceOrderListObject?.serviceOrder?.serviceType,
        serviceElevation: serviceOrderListObject?.serviceOrder?.serviceRequestElevation,
        serviceSite: serviceOrderListObject?.serviceOrder?.serviceSite,
        failureType: serviceOrderListObject?.serviceOrder?.failureType,
        functionalityStatus: serviceOrderListObject?.serviceOrder?.functionalityStatus,
        symptomCode: serviceOrderListObject?.serviceOrder?.symptomCode,
        failureNote: serviceOrderListObject?.serviceOrder?.failureNote,
        bookingType: serviceOrderListObject?.serviceOrder?.bookingType,
        providerType: serviceOrderListObject?.serviceOrder?.providerType,
        rescheduleReasonCode: rescheduleCode[0]?.reasonCode,
        isReschedule: true,
        externalAppointmentNumber: serviceOrderListObject?.serviceOrder?.externalAppointmentNumber,
        bookingNote: bookingNote,
      },
      appointment: {
        groupID: serviceOrderListObject?.serviceOrder?.groupID,
        offerKey: serviceOrderListObject?.serviceOrder?.offerKey,
        appointmentDate:
          serviceOrderListObject?.serviceOrder?.providerType === 'A&E'
            ? selectedData?.date
            : selectedData?.date,
        appointmentTimeSlot:
          serviceOrderListObject?.serviceOrder?.providerType === 'A&E'
            ? selectedSlot
            : selectedSlot,
        consumerRequestDate: moment(
          serviceOrderListObject?.serviceOrder?.consumerRequestDate,
        ).format('YYYY-MM-DD'),
        consumerRequestTime: serviceOrderListObject?.serviceOrder?.consumerRequestWindow,
        specialInstructions: serviceOrderListObject?.specialInstructions,
        appointmentDay: selectedData?.date ? moment(selectedData?.date).format('dddd') : '',
      },
      servicer: {
        servicerID:
          serviceOrderListObject?.serviceOrder?.providerType === 'A&E'
            ? serviceOrderListObject?.serviceOrder?.servicerID
            : serviceOrderListObject?.serviceOrder?.servicerID ||
              serviceOrderListObject?.serviceOrder?.servicerId ||
              '',
        servicerAccount: serviceOrderListObject?.serviceOrder?.servicerAccountId || '',
        servicerName: uiservicerData?.account?.businessName,
        servicerPhone: uiservicerData?.dispatchMethod?.primaryPhone || '',
        servicerWebsite: uiservicerData?.account?.businessWebsite || '',
        bookingType: 'Reschedule',
        providerType: servicerData?.providerType,
        serviceUnitNumber:
          (selectedData?.timeBand && selectedData?.timeBand[0]?.serviceUnitNumber) || '',
      },
      parts: {
        partDistibutorID: '',
      },
      payment: {
        deductible: 0,
        poNumber: '',
        poAmount: 0,
        authorizationNumber: '',
        billTo: '',
        notToExceedAmount: 500,
      },
      additionalFunctions: {
        status1: '',
        status2: '',
        status3: '',
        status4: '',
        status5: '',
        status6: '',
        status7: '',
        status8: '',
        status9: '',
        status10: '',
      },
    }
    const toastId = toast.loading('Rescheduling Appointment', { autoClose: false })
    try {
      const response = await bookAppointment(reqBody)
      toast.dismiss(toastId)
      if (response && response.data.isSuccess) {
        setOpenTimeSlotDialog(false)
        setFinalConfirmationReschedule(true)
        setRescheduleResponse(response.data.data)
        setFinalBookingTimeSlot(reqBody?.appointment)
      } else {
        toast.dismiss(toastId)
        toast.error(response.error.message || 'Something Went Wrong')
      }
    } catch (error) {
      toast.dismiss(toastId)
    }
    setBookingNote('')
  }

  const handleFinalConfirmationReschedule = () => {
    setRescheduleResponse(undefined)
    setRescheduleDialogValue(undefined)
    setRescheduleAppoitnmentDialog(false)
    setFinalConfirmationReschedule(false)
    closeRescheduleDialogParent()
    getServicerOrderParent()
  }

  const openTimeSlot = () => {
    setOpenTimeSlotDialog(true)
  }
  const closeTimeSlot = () => {
    setOpenTimeSlotDialog(false)
  }
  const getServicerData = async () => {
    if (
      existingAppointment?.industryId &&
      servicerData &&
      servicerSubStatus !== 'Locating Servicer'
    ) {
      try {
        const response = await getServicerDetail(servicerData, existingAppointment)
        if (response && response?.data?.isSuccess) {
          setUiServicerData(response.data.data)
        }
      } catch (error) {
        toast.error(error?.message)
      }
    } else {
      await getServiceOrder()
    }
  }
  const getServiceOrder = async () => {
    try {
      const response = await getOrderDetails(servicerData)
      if (response && response?.data?.isSuccess) {
        const obj = {
          customer: {
            address1: response.data.data.consumer.address1,
            address2: response.data.data.consumer.address2,
            businessName: null,
            cellPhone: response.data.data.consumer.phone,
            city: response.data.data.consumer.city,
            countryCode: response.data.data.consumer.countryCode,
            customerId: response.data.data.serviceOrder.crmKey,
            crmKey: response.data.data.serviceOrder.crmKey || 1,
            email: response.data.data.consumer.email,
            firstName: response.data.data.consumer.firstName,
            lastName: response.data.data.consumer.lastName,
            otherPhone: response.data.data.consumer.secondaryPhone,
            stateProvince: response.data.data.consumer.state,
            zipCode: response.data.data.consumer.postalCode,
          },
          product: {
            brandCode: response.data.data.product.brandName,
            installDate: response.data.data.product.installDate,
            modelNumber: response.data.data.product.modelNumber,
            productCode: response.data.data.product.productCode,
            serialNumber: response.data.data.product.serialNumber,
            tierCode: response.data.data.product.productCode,
            totalLiability: response.data.data.product.totalLiability,
            productSequenceNumber: response.data.data.product.productSequenceNumber,
          },
          service: {
            beginSearchDate: today.format('YYYY-MM-DD'),
            numberOfDays: 14,
            procedureCode: response.data.data.serviceOrder?.procedureCode,
            serviceElevation: response.data.data.serviceOrder.serviceRequestElevation,
            serviceNotice: response.data.data.serviceOrder.notice,
            serviceType: response.data.data.serviceOrder.serviceType,
            bookingType: 'firm',
            providerType: 'A&E',
            dealerID: response.data.data.contract.dealerStoreNumber,
            serviceOrderNumber: response.data.data.serviceOrder.serviceOrderId,
          },
          warranty: {
            contractNumber: response.data.data.contract.contractNumber,
            coverageBeginDate: response.data.data.contract.effectiveDate,
            coverageEndDate: response.data.data.contract.expirationDate,
            warrantyType: response.data.data.serviceOrder.warrantyType,
          },
          type: 'reschedule',
        }

        const reassignDataObj = {
          customer: {
            address1: response.data.data.consumer.address1,
            address2: response.data.data.consumer.address2,
            businessName: null,
            cellPhone: response.data.data.consumer.phone,
            city: response.data.data.consumer.city,
            countryCode: response.data.data.consumer.countryCode,
            customerId: response.data.data.serviceOrder.crmKey,
            crmKey: response.data.data.serviceOrder.crmKey || 1,
            email: response.data.data.consumer.email,
            firstName: response.data.data.consumer.firstName,
            lastName: response.data.data.consumer.lastName,
            otherPhone: response.data.data.consumer.secondaryPhone,
            stateProvince: response.data.data.consumer.state,
            zipCode: response.data.data.consumer.postalCode,
          },
          product: {
            brandCode: response.data.data.product.brandName,
            installDate: response.data.data.product.installDate,
            modelNumber: response.data.data.product.modelNumber,
            productCode: response.data.data.product.productCode,
            serialNumber: response.data.data.product.serialNumber,
            tierCode: response.data.data.product.productCode,
            totalLiability: response.data.data.product.totalLiability,
            productSequenceNumber: response.data.data.product.productSequenceNumber,
          },
          service: {
            beginSearchDate: today.format('YYYY-MM-DD'),
            numberOfDays: 14,
            procedureCode: response.data.data.serviceOrder?.procedureCode,
            serviceElevation: response.data.data.serviceOrder.serviceRequestElevation,
            serviceNotice: response.data.data.serviceOrder.notice,
            serviceType: response.data.data.serviceOrder.serviceType,
            bookingType: response.data.data.serviceOrder.bookingType,
            providerType: response.data.data.serviceOrder.providerType,
            dealerID: response.data.data.contract.dealerStoreNumber,
            serviceOrderNumber: response.data.data.serviceOrder.serviceOrderId,
            serviceLocationId: response.data.data.serviceOrder?.serviceLocationId,
            serviceTypeId: response.data.data.serviceOrder?.serviceTypeId,
          },
          warranty: {
            contractNumber: response.data.data.contract.contractNumber,
            coverageBeginDate: response.data.data.contract.effectiveDate,
            coverageEndDate: response.data.data.contract.expirationDate,
            warrantyType: response.data.data.serviceOrder.warrantyType,
            warrantyTypeId: response.data.data.serviceOrder.warrantyTypeId,
          },
          type: 'reassign',
          countryId: uiservicerData?.account?.countryId,
          timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
          timeZoneMinuteDifference: new Date().getTimezoneOffset(),
        }

        setRescheduleAppointmentAEObject(obj)
        setReassignDataObj(reassignDataObj)
        setServiceOrderListObject(response.data.data)
        setServiceOrder(response?.data?.data?.serviceOrder)
        setConsumerDetails(response?.data?.data?.consumer)
        setProductDetails(response?.data?.data?.product)
      }
    } catch (error) {
      toast.error(error?.message)
    }
  }

  useEffect(() => {
    if (showExistingAppointmentDialog && existingAppointment) {
      setServicerData(existingAppointment)
    }
  }, [showExistingAppointmentDialog, existingAppointment])

  useEffect(() => {
    if (showExistingAppointmentDialog && servicerData) {
      getServicerData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExistingAppointmentDialog, servicerData])

  useEffect(() => {
    if (showExistingAppointmentDialog && uiservicerData) {
      getServiceOrder()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExistingAppointmentDialog, uiservicerData])

  useEffect(() => {
    if (showExistingAppointmentDialog && servicerData && rescheduleDialogParent) {
      setRescheduleDialog(true)
      getRescheduleReason()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showExistingAppointmentDialog, servicerData, rescheduleDialogParent])

  useEffect(() => {
    if (showExistingAppointmentDialog && reassignDialogParent && reassignDataObj) {
      getReassignData()
    }
  }, [
    showExistingAppointmentDialog,
    reassignDialogParent,
    reassignDataObj,
    servicerData?.servicerAccountId,
  ])
  const getReassignData = async () => {
    const toastId = toast.loading('Loading...', { autoClose: false })
    try {
      const response = await getAppointments(
        reassignDataObj?.customer?.address1,
        reassignDataObj?.customer?.address2,
        reassignDataObj?.service?.bookingType,
        reassignDataObj?.customer?.businessName,
        reassignDataObj?.customer?.cellPhone,
        reassignDataObj?.customer?.city,
        reassignDataObj?.customer?.countryCode,
        reassignDataObj?.customer?.customerId,
        reassignDataObj?.dealerID?.dealerId,
        reassignDataObj?.customer?.email,
        reassignDataObj?.customer?.firstName,
        reassignDataObj?.customer?.lastName,

        reassignDataObj?.customer?.otherPhone,
        reassignDataObj?.customer?.stateProvince,
        reassignDataObj?.customer?.zipCode,
        reassignDataObj?.product?.brandCode,
        reassignDataObj?.product?.modelNumber,
        reassignDataObj?.product?.productCode,
        reassignDataObj?.product?.tierCode,
        reassignDataObj?.product?.serialNumber,
        reassignDataObj?.service?.beginSearchDate,
        reassignDataObj?.service?.procedureCode,
        reassignDataObj?.service?.serviceElevation,
        reassignDataObj?.service?.serviceNotice,
        reassignDataObj?.service?.serviceType,
        reassignDataObj?.warranty?.contractNumber,
        reassignDataObj?.warranty?.coverageBeginDate,
        reassignDataObj?.warranty?.coverageEndDate,
        reassignDataObj?.warranty?.warrantyType,
        reassignDataObj?.warranty?.warrantyTypeId,
        reassignDataObj?.service?.serviceTypeId,
        reassignDataObj?.service?.serviceLocationId,

        reassignDataObj?.product?.productSequenceNumber,
        reassignDataObj?.service?.providerType,
        reassignDataObj?.customer?.crmKey || 1,
        reassignDataObj?.product?.installDate,
        reassignDataObj?.product?.totalLiability,
        reassignDataObj?.service?.serviceOrderNumber,
        reassignDataObj?.countryId,
        'reassign',
      )
      toast.dismiss(toastId)

      if (response?.data?.isSuccess) {
        toast.dismiss(toastId)
        const newData = JSON.parse(JSON.stringify(response?.data?.data))
        const filteredArray = newData.map((item) => ({
          ...item,
          availability: item.availability?.filter(
            (subItem) => subItem.servicerAccount !== servicerData?.servicerAccountId,
          ),
        }))
        setTotalReassignData(filteredArray)
        const firstSeven = filteredArray.slice(0, 7)
        setReassignData(firstSeven)
        setReassignOpen(true)
      }
    } catch (err) {
      toast.dismiss(toastId)
      toast.error(err?.message)
    }
  }

  useEffect(() => {
    if (showExistingAppointmentDialog && cancelDialogParent && serviceOrderListObject) {
      setOpenCancelService(true)
    }
  }, [showExistingAppointmentDialog, cancelDialogParent, serviceOrderListObject])

  return (
    <React.Fragment>
      {rescheduleDialog && (
        <RescheduleDialog
          open={rescheduleDialog}
          rescheduleReasonList={rescheduleReasonList}
          handleClose={handleRescheduleDialog}
          value={rescheduleDialogValue}
          handleChange={setRescheduleDialogValue}
          openRescheduleAppointmentDialog={handleOpenRescheduleAppointMentDialog}
        />
      )}
      {rescheduleAppointmentDialog && (
        <RescheduleAppointmentDialog
          open={rescheduleAppointmentDialog}
          rescheduleAppointmentDialogData={rescheduleAppointmentDialogData}
          handleClose={handleCloseRescheduleAppointmentDialog}
          servicer={servicerData}
          id={servicerData?.servicerAccountId}
          bookReschedule={(selectedData, selectedSlot, bookingNote, setBookingNote) =>
            handleRescheduleAppointment(selectedData, selectedSlot, bookingNote, setBookingNote)
          }
          openTimeSlot={openTimeSlot}
          closeTimeSlot={closeTimeSlot}
          openTimeSlotDialog={openTimeSlotDialog}
          uiservicerData={uiservicerData}
        />
      )}
      {finalConfirmationReschedule && (
        <ConfirmationRescheduleDialog
          open={finalConfirmationReschedule}
          handleClose={handleFinalConfirmationReschedule}
          data={rescheduleResponse}
          servicer={uiservicerData}
          consumer={serviceOrderListObject}
          finalBookingTimeSlot={finalBookingTimeSlot}
        />
      )}
      {servicerData && servicerData?.bookingType !== 'firm' && (
        <React.Fragment>
          {reassignOpen && (
            <ReassignDialog
              reqBodyGetAvailability={reqBodyGetAvailability}
              rescheduleAppointmentDialogData={rescheduleAppointmentDialogData}
              totalReassignData={totalReassignData}
              uiservicerData={uiservicerData}
              setReassignIndex={setReassignIndex}
              setAppointmentDay={setAppointmentDay}
              setAppointmentDate={setAppointmentDate}
              serviceOrder={serviceOrder}
              setFinalBookingTimeSlot={setFinalBookingTimeSlot}
              servicerData={servicerData}
              setActivefirmAvailabilitydialog={setActivefirmAvailabilitydialog}
              setBookingContent={setBookingContent}
              setReassignData={setReassignData}
              data={reassignData}
              setFirmAvilabilityDialog={setFirmAvilabilityDialog}
              handleClose={handleClose}
              reassignOpen={reassignOpen}
              setHandleConfirmDialog={setHandleConfirmDialog}
            />
          )}
          {handleConfirmDialog && (
            <ConfirmationDialog
              totalReassignData={totalReassignData}
              rescheduleAppointmentDialogData={rescheduleAppointmentDialogData}
              reassignIndex={reassignIndex}
              reassignReason={reassignReason}
              setReassignReason={setReassignReason}
              dropDownAvailability={dropDownAvailability}
              setDropDownAvailability={setDropDownAvailability}
              setFinalBookingTimeSlot={setFinalBookingTimeSlot}
              getServiceOrderAfterBooking={getServiceOrderAfterBooking}
              appointmentDate={appointmentDate}
              setFailureId={setFailureId}
              productDetails={productDetails}
              reassignNote={reassignNote}
              finalBookingTimeSlot={finalBookingTimeSlot}
              serviceOrder={serviceOrder}
              setReassignNote={setReassignNote}
              servicerData={servicerData}
              setFinalConfirmDialog={setFinalConfirmDialog}
              bookingContent={bookingContent}
              data={reassignData}
              handleConfirmDialog={handleConfirmDialog}
              setHandleConfirmDialog={setHandleConfirmDialog}
              setReassignOpen={setReassignOpen}
              consumerDetails={consumerDetails}
              appointmentDay={appointmentDay}
            />
          )}
          {finalConfirmDialog && (
            <FinalConfirmationDialog
              failureId={failureId}
              consumerDetails={consumerDetails}
              productDetails={productDetails}
              setReassignOpen={setReassignOpen}
              setReassignNote={setReassignNote}
              finalBookingTimeSlot={finalBookingTimeSlot}
              serviceOrder={serviceOrder}
              servicerData={servicerData}
              finalConfirmDialog={finalConfirmDialog}
              setFinalConfirmDialog={setFinalConfirmDialog}
              bookingContent={bookingContent}
              data={reassignData}
              handleConfirmDialog={handleConfirmDialog}
              setHandleConfirmDialog={setHandleConfirmDialog}
              appointmentDay={appointmentDay}
              appointmentDate={appointmentDate}
              closeReassignDialogParent={closeReassignDialogParent}
              getServicerOrderParent={getServicerOrderParent}
            />
          )}
        </React.Fragment>
      )}
      {openCancelService && (
        <CancelServiceOrderDialog
          servicerData={servicerData}
          cancelReasons={cancelReasons}
          openCancelService={openCancelService}
          handleToggleCancelService={handleToggleCancelService}
          serviceOrderListObject={serviceOrderListObject}
          uiservicerData={uiservicerData}
          getServicerOrderParent={getServicerOrderParent}
        />
      )}
    </React.Fragment>
  )
}
